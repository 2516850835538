<template>
  <svg
    width="346"
    height="606"
    viewBox="0 0 346 606"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_36_57)">
      <rect
        x="16.7897"
        y="0.947368"
        width="313.263"
        height="189.721"
        rx="8.52632"
        fill="white"
        :stroke="primaryColor"
        stroke-width="1.89474"
      />
      <rect x="23.4058" y="7.56494" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="73.8315" y="7.56445" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="124.256" y="7.56445" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="174.682" y="7.56445" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="225.107" y="7.56445" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="275.532" y="7.56445" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="23.4058" y="68.0747" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="73.8315" y="68.0747" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="124.256" y="68.0747" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="174.682" y="68.0747" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="225.107" y="68.0747" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="275.532" y="68.0747" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="23.4058" y="128.585" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="73.8315" y="128.585" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="124.256" y="128.585" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="174.682" y="128.585" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="225.107" y="128.585" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="275.532" y="128.585" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="23.4058" y="27.7344" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="73.8315" y="27.7344" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="124.256" y="27.7344" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="174.682" y="27.7344" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="225.107" y="27.7344" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="275.532" y="27.7344" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="23.4058" y="88.2446" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="73.8315" y="88.2446" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="124.256" y="88.2446" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="174.682" y="88.2446" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="225.107" y="88.2446" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="275.532" y="88.2446" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="23.4058" y="148.755" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="73.8315" y="148.755" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="124.256" y="148.755" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="174.682" y="148.755" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="225.107" y="148.755" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="275.532" y="148.755" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="23.4058" y="47.9048" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="73.8315" y="47.9048" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="124.256" y="47.9048" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="174.682" y="47.9048" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="225.107" y="47.9048" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="275.532" y="47.9048" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="23.4058" y="108.415" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="73.8315" y="108.415" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="124.256" y="108.415" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="174.682" y="108.415" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="225.107" y="108.415" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="275.532" y="108.415" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="23.4058" y="168.925" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="73.8315" y="168.925" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="124.256" y="168.925" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="174.682" y="168.925" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="225.107" y="168.925" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect x="275.532" y="168.925" width="45.3827" height="15.1276" fill="#E5E6EA" />
      <rect
        x="74.7789"
        y="28.6817"
        width="93.9133"
        height="134.253"
        :fill="primaryColor"
        fill-opacity="0.1"
        :stroke="primaryColor"
        stroke-width="1.89474"
      />
    </g>
    <g filter="url(#filter1_d_36_57)">
      <circle cx="174" cy="286" r="39" fill="white" />
    </g>
    <path
      d="M176.292 264.333C176.292 263.781 175.844 263.333 175.292 263.333L171.709 263.333C171.156 263.333 170.709 263.781 170.709 264.333L170.709 294.894C170.709 295.788 169.624 296.233 168.996 295.596L156.067 282.473C155.675 282.076 155.034 282.076 154.642 282.473L152.082 285.072C151.698 285.461 151.698 286.086 152.082 286.475L172.788 307.491C173.18 307.888 173.821 307.888 174.213 307.491L194.919 286.475C195.302 286.086 195.302 285.461 194.919 285.072L192.359 282.473C191.967 282.076 191.326 282.076 190.934 282.473L178.004 295.596C177.377 296.233 176.292 295.788 176.292 294.894L176.292 264.333Z"
      :fill="primaryColor"
    />
    <g filter="url(#filter2_d_36_57)">
      <rect
        x="16.7892"
        y="379.947"
        width="313.264"
        height="189.722"
        rx="8.52632"
        fill="white"
        :stroke="primaryColor"
        stroke-width="1.89474"
      />
      <rect
        x="28.4482"
        y="389.085"
        width="289.946"
        height="25.2127"
        rx="1.89474"
        fill="#E5E6EA"
        fill-opacity="0.5"
      />
      <rect
        x="28.4482"
        y="459.681"
        width="289.946"
        height="25.2127"
        rx="1.89474"
        fill="#E5E6EA"
        fill-opacity="0.5"
      />
      <rect
        x="28.4482"
        y="530.276"
        width="289.946"
        height="25.2127"
        rx="1.89474"
        fill="#E5E6EA"
        fill-opacity="0.5"
      />
      <rect
        x="28.4482"
        y="424.383"
        width="289.946"
        height="25.2127"
        rx="1.89474"
        fill="#E5E6EA"
        fill-opacity="0.5"
      />
      <rect
        x="28.4482"
        y="494.979"
        width="289.946"
        height="25.2127"
        rx="1.89474"
        fill="#E5E6EA"
        fill-opacity="0.5"
      />
      <circle cx="43.5758" cy="401.691" r="10.0851" fill="#FFD8C4" />
      <circle cx="43.5758" cy="472.287" r="10.0851" fill="#B7EBD8" />
      <circle cx="43.5758" cy="436.989" r="10.0851" fill="#C4DBFF" />
      <circle cx="43.5758" cy="507.585" r="10.0851" fill="#FFD8C4" />
      <circle cx="43.5758" cy="542.883" r="10.0851" fill="#DBC6F5" />
      <rect x="101.565" y="399.171" width="60.5104" height="5.04253" rx="2.52127" fill="#C4C4C4" />
      <rect x="101.565" y="434.468" width="60.5104" height="5.04253" rx="2.52127" fill="#C4C4C4" />
      <rect x="101.565" y="469.767" width="60.5104" height="5.04253" rx="2.52127" fill="#C4C4C4" />
      <rect x="101.565" y="505.063" width="60.5104" height="5.04253" rx="2.52127" fill="#C4C4C4" />
      <rect x="101.565" y="540.361" width="60.5104" height="5.04253" rx="2.52127" fill="#C4C4C4" />
      <rect x="222.586" y="399.171" width="32.7765" height="5.04253" rx="2.52127" fill="#C4C4C4" />
      <rect x="222.586" y="434.468" width="32.7765" height="5.04253" rx="2.52127" fill="#C4C4C4" />
      <rect x="222.586" y="469.767" width="32.7765" height="5.04253" rx="2.52127" fill="#C4C4C4" />
      <rect x="222.586" y="505.063" width="32.7765" height="5.04253" rx="2.52127" fill="#C4C4C4" />
      <rect x="222.586" y="540.361" width="32.7765" height="5.04253" rx="2.52127" fill="#C4C4C4" />
      <rect x="270.49" y="399.171" width="32.7765" height="5.04253" rx="2.52127" fill="#C4C4C4" />
      <rect x="270.49" y="434.468" width="32.7765" height="5.04253" rx="2.52127" fill="#C4C4C4" />
      <rect x="270.49" y="469.767" width="32.7765" height="5.04253" rx="2.52127" fill="#C4C4C4" />
      <rect x="270.49" y="505.063" width="32.7765" height="5.04253" rx="2.52127" fill="#C4C4C4" />
      <rect x="270.49" y="540.361" width="32.7765" height="5.04253" rx="2.52127" fill="#C4C4C4" />
    </g>
    <defs>
      <filter
        id="filter0_d_36_57"
        x="0.842285"
        y="0"
        width="345.158"
        height="226.616"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="7.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0862745 0 0 0 0 0.337255 0 0 0 0 1 0 0 0 0.15 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_36_57" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_36_57" result="shape" />
      </filter>
      <filter
        id="filter1_d_36_57"
        x="120"
        y="247"
        width="108"
        height="113"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0862745 0 0 0 0 0.337255 0 0 0 0 1 0 0 0 0.15 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_36_57" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_36_57" result="shape" />
      </filter>
      <filter
        id="filter2_d_36_57"
        x="0.841797"
        y="379"
        width="345.158"
        height="226.616"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="7.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0862745 0 0 0 0 0.337255 0 0 0 0 1 0 0 0 0.15 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_36_57" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_36_57" result="shape" />
      </filter>
    </defs>
  </svg>
</template>

<script setup lang="ts">
const primaryColor = computed(() => {
  return getPrimaryColors().primary500;
});
</script>
